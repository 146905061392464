import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { UserState } from "./context/User";

import "./styles/global.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <UserState>
      <App />
    </UserState>
  </React.StrictMode>
);
